import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper, numFields } from '../utils/backendHelper';
import zrDocument, { ZRDocument, Value } from '../model/zrDocument';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';
import { FacetSearchData, SearchData } from '../model/smallPayloadModels/searchData';
import i18n from '@/i18n';

const logger = new Logger('backend.ZRDocument');

const searchableColumns: Map<string, string> = new Map([['InvoiceNumber', 'Belegnummer_Extern']]);
const defaultOrSearchFields = [
  'belegnummer_Extern',
  'kommission_Nummer',
  'zrNummer_Lieferant',
  'name_Lieferant',
  'zrNummer_Empfaenger',
  'betrag_Brutto',
  'betrag_Netto',
];

export interface BackendZRDocument {
  getZRDocument(id: string): AxiosPromise<ZRDocument>;
  getZRDocuments: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getZRDocumentsFacetSearch: (facetSearch: FacetSearchData) => AxiosPromise<Value>;
  getZRDocumentRechnungsnummer(searchData?: SearchData): AxiosPromise<any>;
  getZrDocumentsStatuses(searchData?: SearchData): AxiosPromise<any>;
  getZrDocumentsMembers(searchData?: SearchData): AxiosPromise<any>;
  getZrDocumentsSuppliers(searchData?: SearchData): AxiosPromise<any>;
  getZrDocumentsExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
  getZrDocumentsPdfReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
  getAzureFile(data: any): AxiosPromise<any>;
  getZrDocumentsPdfReportView(data: any): AxiosPromise<any>;
  deleteZRDocument(id: string): AxiosPromise;
  updateZRDocument(ZRDocument: ZRDocument): AxiosPromise<any>;
  updateZRDocumentStatus(ZRDocument: any): AxiosPromise<any>;
  updateZRDocumentCommission(ZRDocumentCommission: any): AxiosPromise<any>;
}

export const defaultBackendZRDocument: BackendZRDocument = {
  getZRDocument(id: string): AxiosPromise<ZRDocument> {
    let url = `${URLS.zrDocument}/${id}?lang=${i18n.locale}`;
    return instance.get<ZRDocument>(url);
  },
  getZRDocuments(searchParams: any, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        console.log('reset orClauseFieldsIds :>> ');
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField.searchWord;
        searchParams.orClauseFieldsIds = defaultOrSearchFields;

        localStorage.setItem('zr-documents-filter', allChipField.searchWord);
      }
      let andClauseFieldsIds: Array<any> = searchParams.andClauseFieldsIds;

      andClauseFieldsIds.forEach((element: any, index: number) => {
        const chipField = searchableColumns.has(element.chipField)
          ? searchableColumns.get(element.chipField)
          : element.chipField;

        if (numFields.includes(chipField.toLowerCase())) {
          var resultAnd = new ODataFilterBuilder('and');

          resultAnd.ge(chipField, Math.trunc(+element.searchWord), false);
          resultAnd.lt(chipField, Math.trunc(+element.searchWord + 1), false);
          odfb.and(resultAnd);
        } else if (element.chipField == 'all') {
          // do nothing
        } else {
          odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
        }
      });
    }

    searchParams.filter = searchParams.filter?.replace(',', '.'); // with `,` (comma) get parsing Odata error on backend
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.zrDocumentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.dataOption.filter,
      odfb
    );
    logger.log(`getZRDocuments${url}`);
    return instance.put<Value>(url, searchData);
  },

  getZRDocumentsFacetSearch(facetData: FacetSearchData): AxiosPromise<any> {
    let url = `${URLS.zrDocument}/GetSearchResult`;
    console.log('facetData :>> ', facetData);
    return instance.put<Value>(url, facetData);
  },
  getZRDocumentRechnungsnummer(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.zrDocument}/GetDistinctRechnungsNummers`;
    return instance.put<Value>(url, searchData);
  },
  getZrDocumentsStatuses(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.zrDocument}/GetDistinctStatuses`;
    return instance.put<Value>(url, searchData);
  },
  getZrDocumentsMembers(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.zrDocument}/GetDistinctMembers`;
    return instance.put<Value>(url, searchData);
  },
  getZrDocumentsSuppliers(searchData: any = {}): AxiosPromise<any> {
    let url = `${URLS.zrDocument}/GetDistinctSuppliers`;
    return instance.put<Value>(url, searchData);
  },
  getZrDocumentsExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened

    payload.dataOption.itemsPerPage = 10000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.zrDocument}/GetExcelReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    logger.debug(`GetExcelReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
  getZrDocumentsPdfReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened

    payload.dataOption.itemsPerPage = 5000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.zrDocument}/GetPdfReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    logger.debug(`GetPdfReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
  getAzureFile(data: any): AxiosPromise<any> {
    logger.debug('GetAzureFile');
    return instance.put<any>(`${URLS.zrDocument}/GetAzureFile`, data, {
      responseType: 'blob',
    });
  },
  getZrDocumentsPdfReportView(data: any): AxiosPromise<any> {
    logger.debug('GetPdfReport');
    return instance.put<any>(`${URLS.zrDocument}/GetPdfReportView`, data, {
      responseType: 'blob',
    });
  },
  deleteZRDocument(id: string): AxiosPromise {
    logger.debug('deleteZRDocument');
    return instance.delete(`${URLS.zrDocument}/${id}`);
  },
  updateZRDocument(ZRDocument: ZRDocument): AxiosPromise<any> {
    logger.debug('updateZRDocument');
    return instance.put<ZRDocument>(`${URLS.zrDocument}/update`, zrDocument.toAPI(ZRDocument));
  },
  updateZRDocumentStatus(zrDocumentStatus: any): AxiosPromise<any> {
    logger.debug('updateZRDocumentStatus');
    return instance.put<ZRDocument>(`${URLS.zrDocument}/updateStatus`, zrDocumentStatus);
  },
  updateZRDocumentCommission(zrDocumentCommission: any): AxiosPromise<any> {
    logger.debug('updateZRDocumentCommission');
    return instance.put<ZRDocument>(`${URLS.zrDocument}/updateCommission`, zrDocumentCommission);
  },
};
