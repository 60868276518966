import i18n from '@/i18n';
export interface ChangevoucherInternalStatus {
  index: number;
  i18nKey: string;
  name?: string;
}

export enum ChangevoucherInternalStatusNameKey {
  open = 'open',
  waiting = 'waiting',
  resubmitted = 'resubmitted',
  booked = 'booked',
  completed = 'completed',
}

const RootI18nKey = 'voucher_internal_status.';

export const changevoucherInternalStatusList: ChangevoucherInternalStatus[] = [
  { index: 0, i18nKey: RootI18nKey + ChangevoucherInternalStatusNameKey.open },
  // { index: 1, i18nKey: RootI18nKey + ChangevoucherInternalStatusNameKey.waiting },
  { index: 2, i18nKey: RootI18nKey + ChangevoucherInternalStatusNameKey.resubmitted },
  { index: 3, i18nKey: RootI18nKey + ChangevoucherInternalStatusNameKey.booked },
  { index: 4, i18nKey: RootI18nKey + ChangevoucherInternalStatusNameKey.completed },
];

export default {
  changevoucherInternalStatusList,
};

export function voucherIntStatusesWithLocale(backendIndices: any = []) {
  let array = [
    { index: 0, statusName: i18n.tc(`voucher_internal_status.open`) },
    //{ index: 1, statusName: i18n.tc(`changevoucherStatusType.waiting`) },
    { index: 2, statusName: i18n.tc(`voucher_internal_status.resubmitted`) },
    { index: 3, statusName: i18n.tc(`voucher_internal_status.booked`) },
    { index: 4, statusName: i18n.tc(`voucher_internal_status.completed`) },
  ];
  if (backendIndices.length > 0) {
    array = array.filter((x) => backendIndices.includes(x.index));
  }

  return array;
}
