export interface Invoice {
  id: string; // ulong
  process_id?: string; // string?
  belegtyp: number; // byte // was DocumentType
  ident_nr?: string; // string? // was DocumentNumber
  kommissionsnr: string; // string
  externe_belegnr: string; // string // was ExternalDocumentReference
  externe_belegdatum: string; // System.DateTime // was DocumentDate
  belegdatum: string; // System.DateTime
  processing_date: string; // System.DateTime?
  faelligkeitsdatum: string; // System.DateTime? // was DueDate
  netto: number; // int
  brutto: number; // int
  waehrung: string; // string // was currency
  mwst_satz: number; // sbyte // was taxRate
  mwst_betrag: number; // int // was taxAmount
  status: string; // string
  zr_nr_debitor: string; // string // was receiverNumber
  zr_nr_kreditor: string; // string // was senderNumber
  total_discount_rate_info?: string; // string?
  // attachment: string; // string
  wochenabschluss_datum: string; // System.DateTime? // was `EndOfWeekDate`
  name_lieferant: string;

  bearbeit_von?: string; // string?
  bearbeit_am: string; // System.DateTime?
  record_id?: string; // string?
  invoices: any; // data for `Änderungsgrund` table

  // new fields GSP-136
  belegart: string; // string?
  urspruengliche_belegart: string; // string?
  urspruengliche_belegnr: string; // string?
  vorgaenger_belegart: string; // string?
  vorgaenger_belegnr: string; // string?
  storno: number; // int?
  stornogrund: string; // string?
  auftragsbestaetigungsnr: string; // string?
  lieferschein_nr: string; // string?
  provisionsfaehig: number; // int?
  skontofaehig: number; // int?
  skontosatz: number // decimal?
  belegherkunft: string; // string?
  dms_nr: string; // string?
  erfasser: string; // string?
  erfassungsdatum: string; // System.DateTime?
  beschreibung: string; // string?
  beschreibung_2: string; // string?
  created: string; // System.DateTime?
  skonto_netto: number // decimal?
  skonto_brutto: number // decimal?
  sofortabzug_satz: number // decimal?
  sofortabzug_netto: number // decimal?
  sofortabzug_brutto: number // decimal?
  gesamtabzug_netto: number // decimal?
  gesamtabzug_brutto: number // decimal?

  blockierung_grund: string;

  // frontend calculated properties, not exist on backend
  lang?: string;
  DateTimeTitle?: string;
  pdfScreen?: string;

  // data from admin-panel Supplier
  neuerZRStatus: string;
  zrNummer: string;
  zrEnde: string;
  suchname2: string;
  unternehmensnameZUSATZ: string;
  zrBemerkungenzuKreditor_EXTERN: string;
  supplierName: string;
}

export interface Value {
  value: Invoice[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Invoice>): any {
  return {
    id: data?.id || undefined,
    process_id: data?.process_id || '',
    belegtyp: data?.belegtyp || '',
    ident_nr: data?.ident_nr || '',
    kommissionsnr: data?.kommissionsnr || '',
    externe_belegnr: data?.externe_belegnr || '',
    externe_belegdatum: data?.externe_belegdatum || '',
    belegdatum: data?.belegdatum || '',
    processing_date: data?.processing_date || '',
    faelligkeitsdatum: data?.faelligkeitsdatum || '',
    netto: data?.netto || 0,
    brutto: data?.brutto || 0,
    waehrung: data?.waehrung || '',
    mwst_satz: data?.mwst_satz || '',
    mwst_betrag: data?.mwst_betrag || 0,
    status: data?.status || '',
    zr_nr_debitor: data?.zr_nr_debitor || '',
    zr_nr_kreditor: data?.zr_nr_kreditor || '',
    total_discount_rate_info: data?.total_discount_rate_info || '',
    // attachment: data?.attachment || '',
    wochenabschluss_datum: data?.wochenabschluss_datum || undefined,
    name_lieferant: data?.name_lieferant || '',

    bearbeit_von: data?.bearbeit_von || '',
    bearbeit_am: data?.bearbeit_am || '',
    record_id: data?.record_id || '',
    Invoices: data?.invoices || undefined,


    belegart: data?.belegart || '',
    urspruengliche_belegart: data?.urspruengliche_belegart || '',
    urspruengliche_belegnr: data?.urspruengliche_belegnr || '',
    vorgaenger_belegart: data?.vorgaenger_belegart || '',
    vorgaenger_belegnr: data?.vorgaenger_belegnr || '',
    storno: data?.storno || 0,
    stornogrund: data?.stornogrund || '',
    auftragsbestaetigungsnr: data?.auftragsbestaetigungsnr || '',
    lieferschein_nr: data?.lieferschein_nr || '',
    provisionsfaehig: data?.provisionsfaehig || 0,
    skontofaehig: data?.skontofaehig || 0,
    skontosatz: data?.skontosatz || undefined,
    belegherkunft: data?.belegherkunft || '',
    dms_nr: data?.dms_nr || '',
    erfasser: data?.erfasser || '',
    erfassungsdatum: data?.erfassungsdatum || '',
    beschreibung: data?.beschreibung || '',
    beschreibung_2: data?.beschreibung_2 || '',
    created: data?.created || '',
    skonto_netto: data?.skonto_netto || undefined,
    skonto_brutto: data?.skonto_brutto || undefined,
    sofortabzug_satz: data?.sofortabzug_satz || undefined,
    sofortabzug_netto: data?.sofortabzug_netto || undefined,
    sofortabzug_brutto: data?.sofortabzug_brutto || undefined,
    gesamtabzug_netto: data?.gesamtabzug_netto || undefined,
    gesamtabzug_brutto: data?.gesamtabzug_brutto || undefined,
    blockierung_grund: data?.blockierung_grund || undefined,
  };
}

function parse(data?: Partial<Invoice>): Invoice {
  return {
    id: data?.id || '',
    process_id: data?.process_id || '',
    belegtyp: data?.belegtyp || 0,
    ident_nr: data?.ident_nr || '',
    kommissionsnr: data?.kommissionsnr || '',
    externe_belegnr: data?.externe_belegnr || '',
    externe_belegdatum: data?.externe_belegdatum || '',
    belegdatum: data?.belegdatum || '',
    processing_date: data?.processing_date || '',
    faelligkeitsdatum: data?.faelligkeitsdatum || '',
    netto: data?.netto || 0,
    brutto: data?.brutto || 0,
    waehrung: data?.waehrung || '',
    mwst_satz: data?.mwst_satz || 0,
    mwst_betrag: data?.mwst_betrag || 0,
    status: data?.status || '',
    zr_nr_debitor: data?.zr_nr_debitor || '',
    zr_nr_kreditor: data?.zr_nr_kreditor || '',
    total_discount_rate_info: data?.total_discount_rate_info || '',
    // attachment: data?.attachment || '',
    wochenabschluss_datum: data?.wochenabschluss_datum || '',
    name_lieferant: data?.name_lieferant || 'Lieferant_1',

    bearbeit_von: data?.bearbeit_von || '',
    bearbeit_am: data?.bearbeit_am || '',
    record_id: data?.record_id || '',
    invoices: data?.invoices || undefined,

    belegart: data?.belegart || '',
    urspruengliche_belegart: data?.urspruengliche_belegart || '',
    urspruengliche_belegnr: data?.urspruengliche_belegnr || '',
    vorgaenger_belegart: data?.vorgaenger_belegart || '',
    vorgaenger_belegnr: data?.vorgaenger_belegnr || '',
    storno: data?.storno || 0,
    stornogrund: data?.stornogrund || '',
    auftragsbestaetigungsnr: data?.auftragsbestaetigungsnr || '',
    lieferschein_nr: data?.lieferschein_nr || '',
    provisionsfaehig: data?.provisionsfaehig || 0,
    skontofaehig: data?.skontofaehig || 0,
    skontosatz: data?.skontosatz || 0,
    belegherkunft: data?.belegherkunft || '',
    dms_nr: data?.dms_nr || '',
    erfasser: data?.erfasser || '',
    erfassungsdatum: data?.erfassungsdatum || '',
    beschreibung: data?.beschreibung || '',
    beschreibung_2: data?.beschreibung_2 || '',
    created: data?.created || '',
    skonto_netto: data?.skonto_netto || 0,
    skonto_brutto: data?.skonto_brutto || 0,
    sofortabzug_satz: data?.sofortabzug_satz || 0,
    sofortabzug_netto: data?.sofortabzug_netto || 0,
    sofortabzug_brutto: data?.sofortabzug_brutto || 0,
    gesamtabzug_netto: data?.gesamtabzug_netto || 0,
    gesamtabzug_brutto: data?.gesamtabzug_brutto || 0,

    blockierung_grund: data?.blockierung_grund || '',

    neuerZRStatus: data?.neuerZRStatus || '',
    zrNummer: data?.zrNummer || '',
    zrEnde: data?.zrEnde || '',
    suchname2: data?.suchname2 || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    zrBemerkungenzuKreditor_EXTERN: data?.zrBemerkungenzuKreditor_EXTERN || '',
    supplierName: data?.supplierName || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
