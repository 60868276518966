import { ActionTree } from 'vuex';
import { ChangeVoucherState } from './types';
import { RootState } from '../../types';
import { defaultBackendChangeVoucher } from '@/shared/backend/changeVoucher';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import changeVoucher, { Value, ChangeVoucher, CountFiltersRecords } from '@/shared/model/changeVoucher';
import {
  ChangeVoucherFacetSearchData,
  ChangeVoucherSearchData,
} from './../../../model/smallPayloadModels/changeVoucherSearchData';
import DownloadUtils from '@/shared/utils/DownloadUtils';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.changeVouchers');
export const actions: ActionTree<ChangeVoucherState, RootState> = {
  getChangeVouchers(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; changeVoucherSearchData?: any }
  ) {
    commit('setChangeVouchersIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getChangeVouchersSearchParams;
    let rightsData = rootGetters['auth/rightsData'];
    let changeVoucherSearchData = payload?.changeVoucherSearchData ?? { ...rightsData}; // (GSP-289) pass `zrNummer` and `isUserInAzureAdminGroup` if empty `changeVoucherSearchData` (add record from via backend API (Swagger/Postman))

    return defaultBackendChangeVoucher
      .getChangeVouchers(searchParams, changeVoucherSearchData)
      .then((response: AxiosResponse<Value>) => {
        let suppliersInfo = rootGetters['auth/supplierTooltipInfo'];
        if (suppliersInfo) {
          // ONLY_DEV (GSP-117) for now loading tooltip only for dev
          response.data.value = response.data.value.map((item: any) => {
            return Object.assign(
              item,
              suppliersInfo.find((y: any) => y.zrNummer == item.zr_number_supplier)
            );
          });
        }

        commit('setChangeVouchers', response.data);
        commit('setChangeVouchersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setChangeVouchersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucher({ commit, dispatch }, id: string) {
    return defaultBackendChangeVoucher
      .getChangeVoucher(id)
      .then((response: AxiosResponse<ChangeVoucher>) => {
        commit('setChangeVoucher', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getSupplierMembers({ commit, dispatch }, payload: { zrNummer: string; isUserInAzureAdminGroupGetter: any }) {
    return defaultBackendChangeVoucher
      .getSupplierMembers(payload.zrNummer, payload.isUserInAzureAdminGroupGetter)
      .then((response: AxiosResponse<ChangeVoucher>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateChangeVoucher({ commit, dispatch }, file: ChangeVoucher) {
    return defaultBackendChangeVoucher
      .updateChangeVoucher(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucher;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.changeVoucher_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateChangeVoucherStatus({ commit, dispatch }, file: ChangeVoucher) {
    return defaultBackendChangeVoucher
      .updateChangeVoucherStatus(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucher;
          }>
        ) => {
          // module_utils.ok(i18n.tc(`success.changeVoucher_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        logger.error(e);
        // module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateChangeVoucherRgFields({ commit, dispatch }, data: any) {
    return defaultBackendChangeVoucher
      .updateChangeVoucherRgFields(data)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucher;
          }>
        ) => {
          // module_utils.ok(i18n.tc(`success.changeVoucher_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error updateChangeVoucherRgFields', commit, e, logger);
        throw e;
      });
  },


  creaateChangeVoucher({ commit, dispatch }, file: ChangeVoucher) {
    return defaultBackendChangeVoucher
      .creaateChangeVoucher(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucher;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.changeVoucher_create`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  createAufhebungChangeVoucher({ commit, dispatch }, file: ChangeVoucher) {
    return defaultBackendChangeVoucher
      .createAufhebungChangeVoucher(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucher;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.changeVoucher_create`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteChangeVoucher({ commit, dispatch }, id: string) {
    return defaultBackendChangeVoucher
      .deleteChangeVoucher(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ChangeVoucher;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherAeaTyps({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherAeaTyps(payload)
      .then((response: AxiosResponse<any>) => {
        commit('setChangeVoucherAeaTyps', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherAeaTyps error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherMinMaxBruttoNetto({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherMinMaxBruttoNetto(payload)
      .then((response: AxiosResponse<any>) => {
        commit('setChangeVoucherMinMaxBruttoNetto', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherMinMaxBruttoNetto error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherStatusTypes({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherStatusTypes(payload)
      .then((response: AxiosResponse<any>) => {
        commit('setChangeVoucherStatusTypes', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherStatusTypes error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherIntStatusTypes({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherIntStatusTypes(payload)
      .then((response: AxiosResponse<any>) => {
        commit('setChangeVoucherIntStatusTypes', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherIntStatusTypes error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherMembers({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherMembers(payload)
      .then((response: AxiosResponse<any>) => {
        commit('setChangeVoucherMembers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherMembers error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherSuppliers({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherSuppliers(payload)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherSuppliers error', commit, e, logger);
        throw e;
      });
  },
  getChangeVouchersExcelReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    let searchParams = payload?.searchParams ?? getters.getChangeVouchersSearchParams;
    return defaultBackendChangeVoucher
      .getChangeVouchersExcelReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVouchersExcelReport error', commit, e, logger);
        throw e;
      });
  },
  getChangeVouchersPdfReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    let searchParams = payload?.searchParams ?? getters.getChangeVouchersSearchParams;
    return defaultBackendChangeVoucher
      .getChangeVouchersPdfReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getInvoicesPdfReport error', commit, e, logger);
        throw e;
      });
  },
  updateChangeVoucherSearchData({ commit, dispatch }, data: any) {
    commit('setChangeVoucherSearchData', data);
  },
  updateChangeVoucherSuppliers({ commit, dispatch }, data: any) {
    commit('setChangeVoucherSuppliers', data);
  },
  updateInitialLocalStorageChangeVoucherSearchData({ commit, dispatch }, data: any) {
    commit('setInitialLocalStorageChangeVoucherSearchData', data);
  },

  getChangeVouchersFacetSearch({ commit, dispatch }, payload: ChangeVoucherFacetSearchData) {
    return defaultBackendChangeVoucher
      .getChangeVoucherFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVouchersFacetSearch error', commit, e, logger);
        throw e;
      });
  },
  getChangeVoucherDetailsPdfReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendChangeVoucher
      .getChangeVoucherDetailsPdfReport(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getChangeVoucherDetailsPdfReport error', commit, e, logger);
        throw e;
      });
  },
  getCountFiltersRecors({ commit, dispatch }, payload?: ChangeVoucherSearchData) {
    return defaultBackendChangeVoucher
      .getCountFiltersRecors(payload)
      .then((response: AxiosResponse<CountFiltersRecords>) => {        
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getCountFiltersRecors error', commit, e, logger);
        throw e;
      });
  },
};
