import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import { statusesWithLocale, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import DateUtils from '@/shared/utils/DateUtils';
import SupplierTooltip from './supplier-tooltip/supplier-tooltip.vue';

const logger = new Logger('search-form');
const zrDocumentModule = namespace('zrDocument');
const authModule = namespace('auth');

@Component({
  components: { DateField, SupplierTooltip },
})
export default class SearchForm extends Vue {
  @Ref('rechnungsnummerCombobox')
  private refRechnungsnummerCombobox!: any;
  @Ref('statusesCombobox')
  private refStatusesCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;

  @Ref('suppliersCombobox')
  private refSuppliersCombobox!: any;

  @zrDocumentModule.Action('getZrDocumentsRechnungsnummers')
  private actionGetZrDocumentsRechnungsnummers!: any;
  @zrDocumentModule.Getter('getZrDocumentsRechnungsnummers')
  private getZrDocumentsRechnungsnummers!: any;

  @zrDocumentModule.Action('getZrDocumentsStatuses')
  private actionGetZrDocumentsStatuses!: any;
  @zrDocumentModule.Getter('getZrDocumentsStatuses')
  private getZrDocumentsStatuses!: any;

  @zrDocumentModule.Action('getZrDocumentsMembers')
  private actionGetZrDocumentsMembers!: any;
  @zrDocumentModule.Getter('getZrDocumentsMembers')
  private getZrDocumentsMembers!: any;

  @zrDocumentModule.Action('getZrDocumentsSuppliers')
  private actionGetZrDocumentsSuppliers!: any;
  @zrDocumentModule.Getter('getZrDocumentsSuppliers')
  private getZrDocumentsSuppliers!: any;

  @zrDocumentModule.Action('resetSearchFormData')
  private actionResetSearchFormData!: any;
  @zrDocumentModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;
  @zrDocumentModule.Action('updateInitialLocalStorageSearchData')
  private actionUpdateInitialLocalStorageSearchData!: any;
  @zrDocumentModule.Action('updateZrDocumentsSuppliers')
  private actionUpdateZrDocumentsSuppliers!: any;
  @zrDocumentModule.Getter('getSearchData')
  private getSearchData!: any;
  @zrDocumentModule.Getter('getZRDocumentsSearchParams')
  private zrDocumentsSearchParams!: any;

  @zrDocumentModule.Getter('isSearchDataInitLocalStorageEmpty')
  private isSearchDataInitLocalStorageEmpty!: any;
  @zrDocumentModule.Getter('isSearchDataEmpty')
  private isSearchDataEmpty!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isDevEnv')
  private isDevEnv!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  get isSingleZrNummer() {
    return !!this.zrNummer && !this.zrNummer.includes(',');
  }

  async mounted() {
    const localStorageSearchData = localStorage.getItem('searchData');
    if (!!localStorageSearchData) {
      this.actionUpdateInitialLocalStorageSearchData(localStorageSearchData);
    }

    //avoid extra query if localStorage is empty on initial load
    if (localStorageSearchData && !this.isSearchDataInitLocalStorageEmpty) {
      this.isSearchDataWasCleared = true; // avoid extra call to backend
      let obj = JSON.parse(localStorageSearchData);
      this.searchData = Object.assign({}, obj);

      this.$nextTick(() => {
        this.searchData = Object.assign({}, obj); // fix strange bug that data not load in inputs in `search-forms` from data
      });
    }

    var promiseAll = [];

    if (this.isUserInAzureAdminGroupGetter || (!this.isUserInAzureAdminGroupGetter && !this.isSingleZrNummer)) {
      promiseAll.push(this.getMembers());
    }

    if (this.isUserInAzureAdminGroupGetter || !(!this.isUserInAzureAdminGroupGetter && this.zrNummer == '-')) {
      promiseAll.push(this.getRechnungsnummers(), this.getStatuses(), this.getSuppliers());
      await Promise.all(promiseAll)
        .then((result) => {})
        .catch((err) => {});
    }

    // console.log('this.$i18n.locale :>> ', this.$i18n.locale);
    // console.log('this.statuses :>> ', this.statuses);
  }

  clearAllSuppliers() {
    console.log('clearAllSuppliers :>> ');
  }

  isSearchDataWasCleared = false;
  @Watch('isSearchDataEmpty', { deep: true })
  public async onIsSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    logger.log('isSearchDataEmpty isSearchDataEmpty :>> ', newVal);
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `searchData` (watcher glitch without this variable)
      this.searchData = searchData.defaultData();
    }
  }

  @Watch('searchData', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    // avoid loop when `searchData` was reset
    if (!this.isSearchDataWasCleared) {
      // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
      const payload = Object.assign({}, this.searchData);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.zrNummer = this.getZrNummerDependOnRole();

      this.actionUpdateSearchData(payload); // (GSP-075) use `Object.assign` to break reference for `searchData` to get new data from backend on any input clear or multiple select
    }
    this.isSearchDataWasCleared = false;
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.zrdocumentZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  private clearFacetFilter: boolean = false;
  @Watch('zrDocumentsSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }

  private loadingStatuses = false;
  private async getStatuses() {
    if (this.getZrDocumentsStatuses?.length > 0) {
      this.statuses = this.getZrDocumentsStatuses;
    } else {
      this.loadingStatuses = true;
      let payload = this.setSearchFormFiltersPayload();
      await this.actionGetZrDocumentsStatuses(payload)
        .then((result: any) => {
          this.statuses = this.getZrDocumentsStatuses; // result transformation in `setZrDocumentsStatuses` mutation
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingStatuses = false;
        });
    }
  }

  private loadingRechnungsnummers = false;
  private async getRechnungsnummers() {
    if (this.getZrDocumentsRechnungsnummers?.length > 0) {
      this.rechnungsnummers = this.getZrDocumentsRechnungsnummers;
    } else {
      this.loadingRechnungsnummers = true;
      let payload = this.setSearchFormFiltersPayload();
      await this.actionGetZrDocumentsRechnungsnummers(payload)
        .then((result: any) => {
          this.rechnungsnummers = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingRechnungsnummers = false;
        });
    }
  }

  private loadingMembers = false;
  private async getMembers() {
    if (this.getZrDocumentsMembers?.length > 0) {
      this.members = this.getZrDocumentsMembers;
    } else {
      this.loadingMembers = true;
      let payload = this.setSearchFormFiltersPayload();
      await this.actionGetZrDocumentsMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;
        });
    }
  }

  private loadingSuppliers = false;
  private async getSuppliers() {
    if (this.getZrDocumentsSuppliers?.length > 0) {
      // FOR TEST will delete later
      console.log('getSuppliers getSuppliers getSuppliers GETTER:>> ', this.getZrDocumentsSuppliers);

      this.suppliers = this.getZrDocumentsSuppliers;
    } else {
      this.loadingSuppliers = true;
      let payload = this.setSearchFormFiltersPayload();
      await this.actionGetZrDocumentsSuppliers(payload)
        .then((result: any) => {
          // FOR TEST will delete later
          console.log('getSuppliers getSuppliers getSuppliers ACTION:>> ', result);
          //this.supplierTooltipInfo;//
          // this.suppliers = result;
          if (this.supplierTooltipInfo) {
            // ONLY_DEV (GSP-117) for now loading tooltip only for dev
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );
            this.actionUpdateZrDocumentsSuppliers(this.suppliers);
          } else {
            this.suppliers = result;
          }

        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;
        });
    }
  }

  searchData = searchData.defaultData();

  rechnungsnummers = [];
  statuses: any = [];
  members = [];
  suppliers = [];
  deltas = [];

  @Watch('$i18n.locale')
  private onLocaleChanged() {
    console.log('locale :>> ', this.$i18n.locale);
    let backendIndices = this.getZrDocumentsStatuses.map((x: any) => x.index);
    this.statuses = statusesWithLocale(backendIndices);
    console.log('zrStatusesList :>> ', this.statuses);
  }

  searchArticles() {
    var data = Object.assign({}, this.searchData); // avoid sending requests when change searchData (watcher in `my-selection-table` component)
    this.actionUpdateSearchData(data);
  }

  changeDate(value: any) {
    this.searchData.datum = value || undefined; // new Date(value).toJSON();

    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.searchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.searchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  //#region Filtering autocomplete data based on previous autocomplete values
  private setSearchFormFiltersPayload() {
    let newVal = this.searchData;
    let chipDataString= this.zrDocumentsSearchParams.andClauseFieldsIds.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
    let payload: SearchData = {
      rechnungsnummer: newVal.rechnungsnummer,
      status: newVal.status,
      mitglied: newVal.mitglied,
      lieferant: newVal.lieferant,
      datum: newVal.datum,
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
      chipDataString: chipDataString,
    };
    return payload;
  }

  showMenu() {
    this.refRechnungsnummerCombobox.activateMenu();
  }

  reloadRechnungsnummersList: boolean = false;
  reloadStatusesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;

  filterRechnungsnummers() {
    if ((this.searchData.rechnungsnummer?.length > 0 ||
      this.searchData.status?.length > 0 ||
      this.searchData.mitglied?.length > 0 ||
      this.searchData.lieferant?.length > 0)
      || this.reloadRechnungsnummersList
      || this.zrDocumentsSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingRechnungsnummers = true;
      let payload = this.setSearchFormFiltersPayload();
      this.reloadRechnungsnummersList = !this.reloadRechnungsnummersList;

      this.actionGetZrDocumentsRechnungsnummers(payload)
        .then((result: any) => {
          console.log('result :>> ', result);
          this.rechnungsnummers = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingRechnungsnummers = false;
          console.log('RechnungsnummerCombobox :>> ', this.refRechnungsnummerCombobox);
          // this.refRechnungsnummerCombobox.activateMenu();

          this.$nextTick(() => {
            this.refRechnungsnummerCombobox.activateMenu();
          });
        });
    }
  }

  filterStatus() {
    if ((this.searchData.rechnungsnummer?.length > 0 ||
      this.searchData.status?.length > 0 ||
      this.searchData.mitglied?.length > 0 ||
      this.searchData.lieferant?.length > 0)
      || this.reloadStatusesList
      || this.zrDocumentsSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingStatuses = true;
      let payload = this.setSearchFormFiltersPayload();
      this.reloadStatusesList = !this.reloadStatusesList;

      this.actionGetZrDocumentsStatuses(payload)
        .then((result: any) => {
          this.statuses = this.getZrDocumentsStatuses; // result transformation from `setZrDocumentsStatuses` mutation
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingStatuses = false;

          this.$nextTick(() => {
            this.refStatusesCombobox.activateMenu();
          });
        });
    }
  }

  filterMembers() {
    // if (!this.isUserInAzureAdminGroupGetter && this.searchData.mitglied?.length == 0) return;
    if ((this.searchData.rechnungsnummer?.length > 0 ||
      this.searchData.status?.length > 0 ||
      this.searchData.mitglied?.length > 0 ||
      this.searchData.lieferant?.length > 0)
      || this.reloadMembersList
      || this.zrDocumentsSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingMembers = true;
      let payload = this.setSearchFormFiltersPayload();
      this.reloadMembersList = !this.reloadMembersList;

      this.actionGetZrDocumentsMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;

          this.$nextTick(() => {
            this.refMembersCombobox.activateMenu();
          });
        });
    }
  }

  filterSupplier() {
    if ((
      this.searchData.rechnungsnummer?.length > 0 ||
      this.searchData.status?.length > 0 ||
      this.searchData.mitglied?.length > 0 ||
      this.searchData.lieferant?.length > 0)
      || this.reloadSuppliersList
      || this.zrDocumentsSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingSuppliers = true;
      let payload = this.setSearchFormFiltersPayload();
      this.reloadSuppliersList = !this.reloadSuppliersList;

      this.actionGetZrDocumentsSuppliers(payload)
        .then((result: any) => {
          // this.suppliers = result;
          if (this.supplierTooltipInfo) { // ONLY_DEV (GSP-117) for now loading tooltip only for dev
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );
          } else {
            this.suppliers = result;
          }

        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;

          this.$nextTick(() => {
            this.refSuppliersCombobox.activateMenu();
          });
        });
    }
  }
  //#endregion

  searchRechnungsnummerInput = '';
  searchStatusInput = '';
  searchMembersInput = '';
  searchModelNameInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchRechnungsnummerInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  clearSearchFormData() {
    this.actionResetSearchFormData();
  }

  get isSearchFormHasData() {
    return (
      this.searchData.rechnungsnummer.length > 0 ||
      this.searchData.status.length > 0 ||
      this.searchData.mitglied.length > 0 ||
      this.searchData.lieferant.length > 0 ||
      this.searchData.datum?.length > 0 ||
      !!this.searchData.datum
    );
  }
}
