import { Logger } from 'fsts';
const logger = new Logger('numberUtils');

export default class NumberUtils {
  /**
   * Round half away from zero ('commercial' rounding)
   * Uses correction to offset floating-point inaccuracies.
   * Works symmetrically for positive and negative numbers.
     * @param num (number)
     * @param decimalPlaces (count of decimals)
   * https://stackoverflow.com/a/48764436
   */
  public static round(num: number, decimalPlaces : number = 0): number {
    const power = Math.pow(10, decimalPlaces);
    const number = (num * power) * (1 + Number.EPSILON);
    return Math.round(number) / power;
  }

  /**
   * Return difference between 2 decimals (or possible decimals)
     * @param number1 (1st number)
     * @param number2 (2nd number)
   */
  public static differenceDecimals (number1:number, number2: number): number {

    const num1Decimals = NumberUtils.countDecimals(number1);
    const num2Decimals = NumberUtils.countDecimals(number2);
    const maxDecimals = Math.max(num1Decimals, num2Decimals); // max decimals from 2 numbers
    const power = Math.pow(10, maxDecimals); // power of 10 (max decimal signs from 2 numbers)


    let result = 0;
    if (power>1) {
      result = (number1 * power - number2 * power) / power;
    } else if (power == 1) {
      //both numbers are integers return simple difference
      result = number1 - number2;
    }
    return result;
  }

  /**
   * Return number of decimal numbers (for Integer is 0)
    * @param number (number value)
    // https://stackoverflow.com/questions/17369098/simplest-way-of-getting-the-number-of-decimals-in-a-number-in-javascript
   */
  public static countDecimals (number: string | number): number {
    if (Math.floor(+number) === number) return 0;

    var str = number.toString();
    if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
        return str.split("-")[1]?.length || 0;
    } else if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return str.split("-")[1]?.length || 0;
  }

  /**
   * Format numbers to correct backend format (`0,00` to `0.00`)
    * @param number (number value)
   */
  public static NumberToCorrectBackendFormat(number: number | string | undefined): number {
    if (!number) {
      return 0;
    }
    let noCommaNum = number.toString().replace(',','.');
    let result = isNaN(+noCommaNum) ? 0 : +noCommaNum;

    return result;
  }
}