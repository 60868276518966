import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DateUtils from '@/shared/utils/DateUtils';

@Component({ name: 'date-field', components: {} })
export default class DateField extends Vue {
  private menu = false;
  private inputDate: string | null = null;
  private datePickerDate: string | null = null;
  private maxDatePickerDate?: string = ''; // for built-in Vuetify datepicker :max Prop

  private namePostfix = 'date'; // used for referencing date-field and show validation error messages based on Locale files

  // Vuetify datepicker has only 1 built-in format, so it cannot be configured from outside
  private datePickerFormat = 'YYYY-MM-DD'; // ISO format

  private localeFirstDayOfWeek = 1;

  // .env values always strings, so return value or empty string
  private dateFormatsFromEnv: string = process.env.VUE_APP_DATE_FORMATS_FOR_MOMENT_JS || '';

  @Prop({ default: 'DD.MM.YYYY', type: String })
  private outputFormat!: string;

  // on the backend we should always send the date in ISO format, so by default set it to TRUE
  @Prop({ default: true, type: Boolean })
  private forceIsoFormatEmit!: boolean; // KPTM-284 (https://dev4you.atlassian.net/browse/KPTM-284?focusedCommentId=10555)

  // MomentJS does not support the 'DMYY', 'DDMYY' date formats so they should be ignored or implemented by us
  // Deleted Default property, since value for dateFormats will be taken from .env or specified from outside via props
  @Prop({ type: Array })
  private dateFormats!: string[];

  @Prop(String)
  private label?: string;

  @Prop(String)
  private hint?: string;
  @Prop({ default: false, type: Boolean })
  private forceHint!: boolean;

  @Prop(String)
  private value?: string;

  @Prop({ default: () => true, type: Function })
  private allowedDates?: Function;

  @Watch('value')
  private valueChanged(newVal: string) {
    if (newVal) this.formatDate(newVal);
    else this.inputDate = null;
  }
  @Watch('outlined')
  private outlinedChanged(newVal: boolean) {
    this.outlined = newVal;
  }

  @Prop({ type: String })
  private locale?: string;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;
  @Prop({ default: false, type: Boolean })
  private readonly!: boolean;
  @Prop({ default: false, type: Boolean })
  private readonlyWithDatePicker!: boolean; // when can choose limited values from `date-picker`
  /** prop for set outlined attribute for textfield component */
  @Prop({ default: true, type: Boolean })
  private outlined!: boolean;
  @Prop({ default: false, type: Boolean })
  private rounded!: boolean;
  @Prop({ default: false, type: Boolean })
  private hideDetails!: boolean;
  @Prop({ default: false, type: Boolean })
  private ranged!: boolean;
  @Prop({ default: false, type: Boolean })
  private additionalChangeDateEvent!: boolean;


  @Prop({ default: 'aggressive', type: String })
  private validationMode!: string; // https://vee-validate.logaretm.com/v2/guide/interaction.html#configuration

  /**
   * The minimum allowed age for the date in years
   */
  @Prop(Number)
  private minAge?: number;

  // parameter which hide datepicker (when true), it does not appear when user clicks on text-field input
  // it could be used in markup without any content like <date-field hideDatepicker></date-field> to hide datepicker
  @Prop({ default: false, type: Boolean })
  private hideDatepicker?: boolean;

  @Prop({ default: true, type: Boolean })
  private onlyOpenOnIcon?: boolean;

  @Prop({ default: () => '' }) private dynamicValidator!: string;

  // by default take dateFormats from .env file, but they can be overridden by specifying props via component tag
  private dateFormatsArray: string[] = this.dateFormats
    ? this.dateFormats
    : this.convertStringToArray(this.dateFormatsFromEnv);

  // need this variable for `ranged` selection to reset `date-picker` to current month after clearing the input (otherwise if select range in April (2023-04) in July (2023-07) then after clear the `date-picker` will show April (not July = current month))
  private currentMonth?: string = '';

  /**
   * if :value props were provided then format it and put in text input
   * could use :value='formatDate(value)', but in this case there will be conflict with value from datepicker
   * and provided value in :value props will be always used ignoring datepicker
   */
  mounted() {
    this.onLocaleChanged();
    this.formatDate(this.value || '');
    this.updateDatepicker();
  }

  /**
   * If text input is empty (could be filled via :value Props or from the database) then set TODAY date ONLY for the datepicker(NOT touching the text input)
   */
  private updateDatepicker() {
    if (!this.inputDate) {
      this.setDatePickerDate();
    }
  }

  /**
   *  Set the datepicker date to "CurrentYear - minAge" or to today's date if :minAge Prop was not provided
   */
  private setDatePickerDate() {
    if (this.minAge) {
      const date = new Date();
      date.setFullYear(date.getFullYear() - this.minAge);

      this.maxDatePickerDate = date.toISOString();
      this.datePickerDate = this.setDate(date, this.datePickerFormat);
    } else {
      Promise.resolve(this.setTodayInDatepicker()).then(() => {
        this.datePickerDate = null; // KP-1021 set border around today's date for empty input (NOT fill it like value was selected)
      });
    }
  }

  /**
   *  Updates the datepicker date to TODAY when the user clicks on the clearable icon (clears text input)
   */
  private clearInput() {
    this.setDatePickerDate();
    this.$emit('changeDate', undefined);
    if (this.additionalChangeDateEvent) {
      this.$emit('scndChangeDateEvent', null);
   }
  }

  /**
   *  Get today date in ISO format
   */
  get todayInIsoFormat() {
    let today = DateUtils.formatDateIsoNoOffset(new Date().toISOString())!.toString();
    return today;
  }

  /**
   *  Sets Today's date ONLY for the datepicker(NOT touching the text input)
   */
  private setTodayInDatepicker() {
    this.datePickerDate = this.setDate(this.todayInIsoFormat, this.datePickerFormat);

    if (this.ranged) {
      this.currentMonth = this.todayInIsoFormat; // return current month after clearing input
      this.$nextTick(() => {
        this.currentMonth = undefined; // should reset `currentMonth` value, otherwise the navigation to previous/next month in `date-picker` NOT work
      });
    }
  }

  /**
   * Converts date to the backendFormat and emit to the parent
   * @param date
   */
  private changeDate(date: string): void {
    // pass the date to parent component with backend format YYYY-MM-dd
    let formattedDate: string = '';

    // correct range dates should be in `2023-07-11,2023-07-15` format
    if (date?.includes('.')) {
      let dates = date.split(',');
      // there is a bug that sometimes date come in `11.07.2023-15.07.2023` format (while should be in `11.07.2023,15.07.2023` format (with comma)) so added additional check
      dates = dates.length == 1 && date.split('-')?.length == 2 ? date.split('-') : dates;
      const datesIso = dates.map((dateValue: string) =>
        this.setDate(dateValue, this.forceIsoFormatEmit ? this.datePickerFormat : this.outputFormat)
      );
      formattedDate = datesIso.join(',');
    } else {
      formattedDate = this.setDate(date, this.forceIsoFormatEmit ? this.datePickerFormat : this.outputFormat);
    }

    this.$emit('changeDate', formattedDate);
    // console.log('this.additionalChangeDateEvent date :>> ', date);
    if (this.additionalChangeDateEvent && date?.includes('.')) { // (GSP-220) update date when type in input
      let isoDate = DateUtils.dotDateStringToISO(date);
      let isDateValid = !isNaN(new Date(isoDate).getDate()) && DateUtils.isValidDateIsoFormat(isoDate);
      if (isDateValid) {
        this.$emit('scndChangeDateEvent', formattedDate);
      }
    }
  }

  @Watch('menu')
  public isMenuChanged(newVal: boolean) {
    if (newVal) {
      (this.$refs['dateFieldRef'] as any).$emit('input', this.inputDate);
    }
  }
  /**
   * Hides datepicker and updates date in the input(text field) from the datepicker
   * when the user selected the date in the datepicker
   * @param date
   */
  private updateInput(date: any): void {
    // single date selection // with range `typeof date` is `Object`, without range is `string`
    if (!this.ranged) {
      this.menu = false; // hide datepicker
      this.inputDate = this.setDate(date, this.outputFormat);
    } else {
      if (date.length <= 1) {
        return; // return to avoid strange bug that sometimes only 1st value without (2nd) is sent on the backend and then backend return exception
      }
      // array of 2 ISO dates (`range` is TRUE)
      const firstDateFromRange = this.setDate(date[0], this.outputFormat);
      const secondDateFromRange = this.setDate(date[1], this.outputFormat);
      this.inputDate = `${firstDateFromRange}-${secondDateFromRange}`;
    }
  }

  /**
   * Formats date to according format IF date is not empty/null/undefined
   * and update values in the text input and the datepicker
   * @param date
   */
  private formatDate(date: string): void {
    if (date) {
      if (!this.ranged) {
        // old logic without range
        this.inputDate = this.setDate(date, this.outputFormat);
        this.changeDate(this.inputDate);
      } else {
        // here we get range date in comma separated dates in ISO format like `2023-07-11,2023-07-15`
        // array of 2 ISO dates (`range` is TRUE)
        let dates = date.split(',');

        if (this.isFirstDateBiggerThanSecond(dates)) {
          dates = dates.reverse();
          date = dates.join(',');
        }
        // when `DateField` input with value loses focus without changing value then next value in `dates` variable: `11.07.2023-15.07.2023`, so should ignore logic with `firstDate` + `secondDate`
        if (dates.length > 1) {
          const firstDate = this.setDate(dates[0], this.outputFormat);
          const secondDate = this.setDate(dates[1], this.outputFormat);
          this.inputDate = `${firstDate}-${secondDate}`;
        } else {
          this.inputDate = date;
        }

        this.changeDate(this.setDate(date, this.outputFormat));
      }

      // convert inputDate to datePickerDate format (YYYY-MM-DD) if the date is valid
      this.datePickerDate = this.validateDate(date) ? this.setDate(date, this.datePickerFormat) : this.datePickerDate;
    }
  }

  /**
   * Compare the 2 dates from range and check if 1st date bigger(later) than 2nd date
   * @param dates
   */
  private isFirstDateBiggerThanSecond(dates: Array<string>) {
    var date1 = new Date(dates[0]);
    var date2 = new Date(dates[1]);
    if (date1 > date2) {
      return true;
    }
    return false;
  }

  /**
   * Updates :first-day-of-week in the datepicker depending on the application locale
   */
  @Watch('$i18n.locale')
  private onLocaleChanged() {
    this.localeFirstDayOfWeek = this.$i18n.locale === 'de' ? 1 : 0;
  }

  /**
   * Validates the date and if VALID then convert from the supported formats to dd.MM.YYYY format
   * if date is not valid just return such value without changes
   * @param date
   * @param outputFormat (format what user will see in input - dd.MM.YYYY)
   */
  private setDate(date: string | Date, outputFormat: string): string {
    let result = '';

    if (date) {
      result = this.validateDate(date)
        ? //? moment(date, this.dateFormatsArray, true).format(outputFormat)
          this.formatDateWithoutMomentJs(date, outputFormat)
        : date.toString();
    }
    return result;
  }

  /**
   * Formats date to according format IF date is not empty/null/undefined
   * and update values in the text input and the datapicker
   * @param date
   */
  private formatDateWithoutMomentJs(date: string | Date, outputFormat: string): string {
    // check if value from DatePicker
    if (this.datePickerFormat == outputFormat) {
      // datepicker value should be always like `'YYYY-MM-DD'; // ISO format` or get `RangeError: Invalid time value` ERROR when try to open datePicker
      let datePickerDate = DateUtils.formatDateIsoNoOffset(date)!.toString();
      return datePickerDate;
    }

    const dateResult = DateUtils.switchMonthAndDay(date).toLocaleDateString('de', {
      // can skip the first argument
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return dateResult;
  }

  /**
   * Checks if date is valid
   * @param date (date value)
   */
  private validateDate(date: string | Date): boolean {
    // TODO : write better custom validation
    // const isValidDate = (Boolean(+date) && date.getDate() == day) https://esganzerla.medium.com/simple-date-validation-with-javascript-caea0f71883c

    if (
      (date.toString().length < 6 || date.toString().length > 10) &&
      !date.toString().match(/\dT\d/) // consider backend ISO date on record creation like `2021-02-26T00:00:00` as VALID
    ) {
      return false;
    }

    // Default JS format is MM/DD/YYYY
    var dateValue = DateUtils.switchMonthAndDay(date);
    const isValid = Boolean(+dateValue);
    return isValid;
  }

  /**
   * Converts .env variable (always string) with supported dateformats from string to array of string
   * @param array
   */
  private convertStringToArray(array: string): string[] {
    const cleanedString = this.sanitizeString(array);
    return cleanedString.split(',');
  }

  /**
   * Deletes unwanted characters (',",[,] and spaces) from .env variable
   * @param string
   */
  private sanitizeString(value: string): string {
    return value.replace(/[[\]'"\s+]/g, '');
  }
}
