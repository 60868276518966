import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './views/app/App.vue';
import './registerServiceWorker';
import router from './router';
import store from './shared/store';
import './plugins/vee-validate';
import i18n from './i18n';
import './class-component-hooks';

import '@mdi/font/css/materialdesignicons.css';
import DateUtils from './shared/utils/DateUtils';
import GeneralUtils from '@/shared/utils/generalUtils';
import { Logger } from 'fsts';
import BackendHub from './plugins/signalR';


import { default as msalPlugin } from 'vue-msal-browser';
import { msalConfig } from './shared/utils/authConfig';

Vue.config.productionTip = false;

Vue.use(BackendHub);
Vue.use(msalPlugin, msalConfig);

// register global component
{
  Vue.component('dialogbox', require('./components/dialogBox/dialogBox.vue').default);

  Vue.prototype.$confirm = {
    open: function (titel: string, message: string, options?: object) {},
  };

  Vue.prototype.$message = {
    open: function (titel: string, message: string, options?: object) {},
  };
}
if (GeneralUtils.isDevEnvironment()) Logger.LOG_LEVEL = 'DEBUG';

Vue.filter('formatDateWithLocaleFull', function (value: string, locale: string) {
  if (value) {
    const returnDate = DateUtils.formatDateWithLocale(value, locale);

    return returnDate;
  }
});

Vue.filter('formatDateWithLocale', function (value: string, locale: string) {
  if (value) {
    const returnDate = DateUtils.formatDateTimeWithLocale(value, locale);

    return returnDate;
  }
});

Vue.filter('formatDateTimeWithLocale', function (value: string, locale: string) {
  if (value) {
    const returnDate = DateUtils.formatDateTimeWithLocale(value, locale, true, true);

    return returnDate;
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
