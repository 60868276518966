import { Vue } from 'vue-property-decorator';
import { HubConnectionBuilder, HubConnection, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { PluginFunction, PluginObject } from 'vue';

export default class BackendHub extends Vue {
  constructor(plugin: PluginObject<unknown> | PluginFunction<unknown>) {
    super(plugin);
  }

  static install(): void {
    // use new Vue instance as an event bus
    const backendHub = new Vue();
    // every component will use this.$backendHub to access the event bus
    Vue.prototype.$backendHub = backendHub;
    // Provide methods to connect/disconnect from the SignalR hub
    let connection: null | HubConnection = null;
    let startedPromise: Promise<void> | null = null;
    let connectedPromise: Promise<void> | null = null;
    let manuallyClosed = false;

    Vue.prototype.startSignalR = () => {
      connection = new HubConnectionBuilder()
        .withUrl(`/signalr/BackendHub`)
        .configureLogging(LogLevel.Information)
        .build();

      /*
       * Checking for connected state by polling the value of the connection.state
       * This is very inconvenient, but I currently do not know another way to
       * determine if a connection has been established.
       * startedPromise cannot be used, as it is fulfilled before the connection has been established
       */
      connectedPromise = new Promise((resolve, reject) => {
        let connectedCheck = () => {
          if (connection?.state == HubConnectionState.Connected) {
            resolve();
            return;
          }
          setTimeout(connectedCheck, 100);
        };
        connectedCheck();
      });

      // Forward hub events through the event, so we can listen for them in the Vue components
      connection.on('UpdateChangeVouchers', () => {
        backendHub.$emit('update:change-vouchers');
      });
      connection.on('UpdateInvoices', () => {
        backendHub.$emit('update:invoices');
      });
      connection.on('UpdateWochenabschluesse', () => {
        backendHub.$emit('update:wochenabschluesse');
      });
      connection.on('UpdateListZrNews', () => {
        backendHub.$emit('update:list-zr-news');
      });
      connection.on('UpdateZrNewsCount', () => {
        backendHub.$emit('update:zr-news-count');
      });

      function start() {
        startedPromise = connection!.start().catch((err: any) => {
          console.error('Failed to connect with hub', err);
        });
        return startedPromise;
      }

      connection.onclose(() => {
        if (!manuallyClosed) start();
      });

      manuallyClosed = false;
      start();
    };
    Vue.prototype.stopSignalR = () => {
      if (!startedPromise) return;

      manuallyClosed = true;
      return startedPromise
        .then(() => connection!.stop())
        .then(() => {
          startedPromise = null;
        });
    };
  }
}