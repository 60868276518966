import i18n from "@/i18n";

export interface ChangevoucherType {
  index: number;
  i18nKey: string;
}

export enum ChangevoucherTypeNameKey {
  credit = 'credit',
  cancellation = 'cancel',
  value_date_change = 'value_date_change',
  contract_penalty_minus = 'contract_penalty_plus',
  contract_penalty_plus = 'contract_penalty_plus',
  bonus_minus = 'bonus_minus',
  bonus_plus = 'bonus_plus',
  debit = 'debit',
  rebooking = 'rebooking',
  cancel_reverse = 'cancel_reverse',
}

const RootI18nKey = 'changevoucherType.';

export const changevoucherTypeList: ChangevoucherType[] = [
  { index: 2, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.credit },
  { index: 3, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.cancellation },
  { index: 4, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.value_date_change },
  { index: 8, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.contract_penalty_minus },
  { index: 18, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.contract_penalty_plus },
  { index: 9, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.bonus_minus },
  { index: 19, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.bonus_plus },
  { index: 10, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.debit },
  { index: 5, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.rebooking },
  { index: 21, i18nKey: RootI18nKey + ChangevoucherTypeNameKey.cancel_reverse },
];

export default {
  changevoucherTypeList,
};

export function voucherAeaTypeWithLocale(backendIndices: any = []) {

  let array = [
    { index: 2, aeaTypeName: i18n.tc(`changevoucherType.credit`) },
    { index: 3, aeaTypeName: i18n.tc(`changevoucherType.cancellation`) },
    { index: 4, aeaTypeName: i18n.tc(`changevoucherType.value_date_change`) },
    { index: 8, aeaTypeName: i18n.tc(`changevoucherType.contract_penalty_minus`) },
    { index: 18, aeaTypeName: i18n.tc(`changevoucherType.contract_penalty_plus`) },
    { index: 9, aeaTypeName: i18n.tc(`changevoucherType.bonus_minus`) },
    { index: 19, aeaTypeName: i18n.tc(`changevoucherType.bonus_plus`) },
    { index: 10, aeaTypeName: i18n.tc(`changevoucherType.debit`) },
    { index: 5, aeaTypeName: i18n.tc(`changevoucherType.rebooking`) },
    { index: 21, aeaTypeName: i18n.tc(`changevoucherType.cancel_reverse`) },
  ];
  if (backendIndices.length > 0) {
    array = array.filter((x) => backendIndices.includes(x.index));
  }

  return array;
}

// 2	Gutschrift
// 3	Stornierung
// 4	Valutadatum Änderung
// 8	Vertragsstrafe negativ
// 8	Vertragsstrafe positiv
// 10	Belastung
// 20	Umbuchung
// 21	Stornierung-Aufhebung
