import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper, numFields } from '../utils/backendHelper';
import invoice, { Invoice, Value } from '../model/invoice';
import ODataFilterBuilder from 'odata-filter-builder';
import { InvoiceFacetSearchData, InvoiceSearchData } from '../model/smallPayloadModels/invoiceSearchData';
import i18n from '@/i18n';
import store from '@/shared/store/index';

const logger = new Logger('backend.Invoice');
const searchableColumns: Map<string, string> = new Map([['InvoiceNumber', 'externe_belegnr'],
['DocumentNumber','ident_nr'],
['CommissionNumber','kommissionsnr'],
['GrossAmount','brutto'],
['NetAmount','netto'],
['ReceiverNumber','zr_nr_debitor'],
['SenderNumber','zr_nr_kreditor'],
['OrderConfirmationNumber','auftragsbestaetigungsnr'],
['DeliveryNoteNumber','lieferschein_nr']]);//,'ExternalDocumentReference']]);
const defaultOrSearchFields = [
  'externe_belegnr',//  'externalDocumentReference',
  'kommissionsnr',//  'commissionNumber',
  'zr_nr_debitor', //  'receiverNumber',
  'zr_nr_kreditor', //  'senderNumber',
  'ident_nr',//  'documentNumber',
  'brutto', //  'grossAmount',
  'netto', //  'netAmount',
];

export interface BackendInvoice {
  getInvoice(id: string): AxiosPromise<Invoice>;
  getInvoiceByDocNumber(document_number: string): AxiosPromise<Invoice>;
  getInvoices: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getInvoiceFacetSearch: (facetSearch: InvoiceFacetSearchData) => AxiosPromise<Value>;
  getInvoicesExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
  getInvoicesPdfReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
  getInvoicesDetailsPdfReport(data: any): AxiosPromise<any>;
  getInvoiceMinMaxBruttoNetto(searchData?: InvoiceSearchData): AxiosPromise<any>;
  getInvoiceDocumentTypes(searchData?: InvoiceSearchData): AxiosPromise<any>;
  getInvoiceMembers(searchData?: InvoiceSearchData): AxiosPromise<any>;
  getInvoiceSuppliers(searchData?: InvoiceSearchData): AxiosPromise<any>;
  getInvoicesForVoucher(data?: any): AxiosPromise<any>;

  deleteInvoice(id: string): AxiosPromise;
  updateInvoice(Invoice: Invoice): AxiosPromise<any>;
}

export const defaultBackendInvoice: BackendInvoice = {
  getInvoice(id: string): AxiosPromise<Invoice> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/${id}?lang=${i18n.locale}`;
    if (window.location.search) {
      url = url + `${window.location.search}`;
      url = url.replace('?doc_number', '&doc_number');
    } 
    return instance.get<Invoice>(url);
  },
  getInvoiceByDocNumber(document_number: string): AxiosPromise<Invoice> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';
    // console.log('i18n :>> ', i18n.locale);
    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/0?doc_number=${document_number}&lang=${i18n.locale}`;
    return instance.get<Invoice>(url);
  },
  getInvoices(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';
    console.log('getInvoices result :>> ', isArchive);

    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        console.log('reset orClauseFieldsIds :>> ');
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField: any = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField?.searchWord;
        searchParams.orClauseFieldsIds = defaultOrSearchFields;

        localStorage.setItem('invoices-filter', allChipField.searchWord);
      }
      let andClauseFieldsIds: Array<any> = searchParams.andClauseFieldsIds;

      andClauseFieldsIds.forEach((element: any, index: number) => {
        const chipField = searchableColumns.has(element.chipField)
          ? searchableColumns.get(element.chipField)
          : element.chipField;

        if (numFields.includes(chipField.toLowerCase())) {
          var resultAnd = new ODataFilterBuilder('and');

          let number = +element.searchWord;
          let numberDelta = number < 0 ? -100 : 100;

          resultAnd.ge(chipField, Math.trunc(number * 100), false);
          resultAnd.lt(chipField, Math.trunc(number * 100 + numberDelta), false);
          odfb.and(resultAnd);
        } else if (element.chipField == 'all') {
          // do nothing
        } else {
          odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
        }
      });
    }

    searchParams.filter = searchParams.filter?.replace(',', '.'); // with `,` (comma) get parsing Odata error on backend

    let url = DefaultBackendHelper.makeUrl(
      `${isArchive ? URLS.invoiceArchiveOdata : URLS.invoiceOdata}`,
      // `${URLS.invoiceOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.dataOption.filter,
      odfb
    );
    logger.log(`getInvoices${url}`);
    return instance.put<Value>(url, searchData);
  },
  getInvoiceFacetSearch(facetData: InvoiceFacetSearchData): AxiosPromise<any> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/GetSearchResult`;
    console.log('facetData :>> ', facetData);
    return instance.put<Value>(url, facetData);
  },

  getInvoiceMinMaxBruttoNetto(searchData: any = {}): AxiosPromise<any> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/GetMinMaxBruttoNetto`;
    return instance.put<Value>(url, searchData);
  },
  getInvoiceDocumentTypes(searchData: any = {}): AxiosPromise<any> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/GetDistinctDocumentTypes`;
    // let url = `${URLS.invoice}/GetDistinctRechnungsNummers`;
    return instance.put<Value>(url, searchData);
  },
  getInvoiceMembers(searchData: any = {}): AxiosPromise<any> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/GetDistinctMembers`;
    return instance.put<Value>(url, searchData);
  },
  getInvoiceSuppliers(searchData: any = {}): AxiosPromise<any> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/GetDistinctSuppliers`;
    return instance.put<Value>(url, searchData);
  },

  getInvoicesForVoucher(searchData: any = {}): AxiosPromise<any> {
    let isArchive = (store.getters['invoice/getIsUseArchiveInvoices'] + '').toString() == 'true';

    let url = `${isArchive ? URLS.invoiceArchive : URLS.invoice}/GetInvoicesForVoucher`;
    return instance.put<Value>(url, searchData);
  },

  deleteInvoice(id: string): AxiosPromise {
    logger.debug('deleteInvoice');
    return instance.delete(`${URLS.invoice}/${id}`);
  },
  updateInvoice(Invoice: Invoice): AxiosPromise<any> {
    logger.debug('updateInvoice');
    return instance.put<Invoice>(`${URLS.invoice}/updateOld`, invoice.toAPI(Invoice));
  },
  getInvoicesExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened

    payload.dataOption.itemsPerPage = 10000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.invoice}/GetInvoicesExcelReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    logger.debug(`GetInvoiceExcelReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
  getInvoicesPdfReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened
    console.log('------------------getInvoicesPdfReport-----------------  >>>:payload dataOption ', payload.dataOption);
    payload.dataOption.itemsPerPage = 5000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.invoice}/GetInvoicesPdfReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    logger.debug(`GetInvoicePdfReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
  getInvoicesDetailsPdfReport(data: any): AxiosPromise<any> {
    logger.debug('GetInvoicesDetailsPdfReport');
    return instance.put<any>(`${URLS.invoice}/GetInvoicesDetailsPdfReport`, data, {
      responseType: 'blob',
    });
  },
};
